import React from "react"
import LayoutComponent from "../components/Layout.js"
import Knap from "../components/Knap.js"

const IndexPage = () => (
    <LayoutComponent>
        <h1>Om Easymed</h1>
        <p>Hos Easymed har vi et tett og tillitsfullt samarbeid med både våre kunder og leverandører i bransjen. Siden vi startet i 1996, har vi vært en fast og stabil leverandør av røntgentilbehør, utstyr og forbruksartikler i hele Skandinavia.</p>
        <p>Vårt sortiment utvider seg i takt med de nyeste radiologiske innovasjoner. Hvis du søker noe spesifikt, så oppfordrer vi deg til å ta kontakt med oss direkte.</p><br />
        <section className="easymed__grid__wrapper">
            <div className="easymed__grid__single__contact">
                <p className="medarbejder__navn">Benjamin Broen</p>
                <p className="medarbejder__titel">Direktør</p>
                <p className="medarbejder__tlf"><a href="tel:004527205354">+45 27205354</a></p>
            </div>
            <div className="easymed__grid__single__contact">
                <p className="medarbejder__navn">Lars Broen</p>
                <p className="medarbejder__titel">Salgsrepresentant</p>
                <p className="medarbejder__tlf"><a href="tel:004526731454">+45 26731454</a></p>
            </div>
        </section>
        <Knap />
    </LayoutComponent>
)

export default IndexPage